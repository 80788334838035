import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-greeting-info-slider',
    templateUrl: './greeting-info-slider.component.html',
    styleUrls: ['./greeting-info-slider.component.css']
})
export class GreetingInfoSliderComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
