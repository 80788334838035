<app-top-bar></app-top-bar>
<header class="header-in-page">

</header>
<app-profile></app-profile>
<header class="header-in-page">

</header>
<app-bottom-bar></app-bottom-bar>
<app-copyright></app-copyright>
