<div class="container">
    <div class="row main-content bg-success text-center">
        <div class="col-md-4 text-center company__info">
            <span class="company__logo" routerLink=""><h2><img src="assets/images/Betterzon.svg"></h2></span>
        </div>
        <div class="col-md-8 col-xs-12 col-sm-12 login_form ">
            <div class="container-fluid">
                <div class="row">
                    <h2>Registration</h2>
                </div>
                <div class="row">
                    <form [formGroup]="form" class="form-group" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <input type="text" formControlName="username" id="username" name="username"
                                   class="form__input" placeholder="Username">
                            <div *ngIf="submitted && me.username.errors" class="invalid-feedback">
                                <div *ngIf="me.username.errors.required">Username is required</div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- <span class="fa fa-lock"></span> -->
                            <input type="email" formControlName="email" name="email" id="email" class="form__input"
                                   placeholder="E-Mail">
                        </div>
                        <div class="row">
                            <!-- <span class="fa fa-lock"></span> -->
                            <input type="password" formControlName="password" name="password" id="password"
                                   class="form__input" placeholder="Password">
                        </div>
                        <!--
                        <div class="row">
                            <input type="password" name="password" id="password_repeated" class="form__input" placeholder="Kennwort bestätigen">
                        </div> -->
                        <div class="row">
                            <input type="submit" value="Sign up" class="btn_signin">
                        </div>
                        <div class="row">
                            <p>Have an account?<a href="/signin">Log In</a></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

