<app-top-bar></app-top-bar>
<header class="masthead bg-transparent text-white text-center" id="w1">

</header>

<div id="mainComponents">
    <app-product-list numberOfProducts="20" [showProductPicture]="true" searchQuery="{{searchTerm}}"
                      type="search"></app-product-list>
</div>

<header class="masthead bg-transparent text-white text-center">

</header>
<app-bottom-bar></app-bottom-bar>
