<app-top-bar></app-top-bar>

<app-greeting-info-slider></app-greeting-info-slider>
<app-hot-deals-widget></app-hot-deals-widget>
<app-about-us></app-about-us>
<app-kunden></app-kunden>

<app-bottom-bar></app-bottom-bar>
<app-copyright></app-copyright>
<!--<div id="mainComponents">
    <div id="searchContainer">
        <input type="text" [(ngModel)]="searchInput" placeholder="Search" (keyup.enter)="startedSearch()">
    </div>
    <div id="productListsContainer">
        <div id="popularSearchesList">
            <h2>Popular Searches</h2>
            <app-product-list numberOfProducts="3" [showProductPicture]="true"
                              type="popularSearches"></app-product-list>
        </div>
        <div id="bestDealsList">
            <h2>Best Deals</h2>
            <app-product-list numberOfProducts="3" [showProductPicture]="true" type="bestDeals"></app-product-list>
        </div>
    </div>
</div>
-->
