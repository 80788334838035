<section class="page-section bg-primary text-white mb-0" id="about">
    <div class="container">
        <!-- About Section Heading-->
        <h2 class="page-section-heading text-center text-uppercase text-white">About</h2>
        <!-- Icon Divider-->
        <div class="divider-custom divider-light">
            <div class="divider-custom-line"></div>
            <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
            <div class="divider-custom-line"></div>
        </div>
        <!-- About Section Content-->
        <div class="row">
            <div class="col-lg-4 ms-auto"><p class="lead">You follow the same passion as we do and you want to find
                alternatives to the de-facto monopolist Amazon?</p></div>
            <div class="col-lg-4 me-auto"><p class="lead">In this case, welcome aboard! We’re happy that you share our
                passion and hope that we can help you achieving this goal with the website.</p></div>
        </div>
    </div>
</section>
