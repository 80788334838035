<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<header class="header">
    <div id="headerContent" class='wrap'>
        <div class="logo">
            <img src="assets/images/Betterzon.svg" alt="Betterzon Logo" width="50px" (click)="clickedLogo()">
        </div>
        <div class="searchBox">
            <input *ngIf="showSearch===true" type="text" [(ngModel)]="searchInput" placeholder="Search"
                   (keyup.enter)="startedSearch()">
        </div>
        <div class="slider">
            <mat-slide-toggle color="primary">dark me</mat-slide-toggle>
        </div>
        <div class="profileIcon">
            <button mat-button [matMenuTriggerFor]="menu">Menu</button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item>Option 1</button>
                <button mat-menu-item>Option 2</button>
                <button mat-menu-item>Option 3</button>
                <button mat-menu-item>Option 4</button>
            </mat-menu>
        </div>
    </div>
</header>
