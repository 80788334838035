<div class="container">
    <div class="row main-content bg-success text-center">
        <div class="col-md-4 text-center company__info">
            <span class="company__logo" routerLink=""><h2><img src="assets/images/Betterzon.svg"></h2></span>
        </div>
        <div class="col-md-8 col-xs-12 col-sm-12 login_form ">
            <div class="container-fluid">
                <div class="row">
                    <h2>Sign In</h2>
                </div>
                <div class="row">
                    <form [formGroup]="loginForm" class="form-group" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <input type="text" formControlName="username" name="username" id="username"
                                   class="form__input" placeholder="Username">
                        </div>
                        <div class="row">
                            <!-- <span class="fa fa-lock"></span> -->
                            <input type="password" formControlName="password" name="password" id="password"
                                   class="form__input" placeholder="Password">
                        </div>
                        <div class="row">
                            <input type="submit" value="Log in" class="btn_signin">
                        </div>
                    </form>
                </div>
                <div class="row">
                    <p>No account yet?<a href="/registration">sign up</a></p>
                </div>
            </div>
        </div>
    </div>
</div>



