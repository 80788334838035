<!--- footer --->

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<footer class="footer">
    <div class='wrap' id="footer-icons">
        <div class="icons">
            <a href="https://github.com/Mueller-Patrick/Betterzon" class="fa fa-github fa-4x icon-3d"></a>
            <a href="https://blog.betterzon.xyz/" class="fa fa-info fa-4x icon-3d"></a>
            <a href="https://github.com/Mueller-Patrick/Betterzon/wiki" class="fa fa-wikipedia-w fa-4x icon-3d"></a>
        </div>
        <div class="blocks" id="copyright">© COPYRIGHT 2020</div>
    </div>
    <div id="imprintSection">
        <a href="/impressum">Imprint</a><br>
        <a href="/datenschutz">Privacy Policy</a>
    </div>
</footer>
